import React from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { CdapGettingStartedPage } from "../components/CDAP/CdapGettingStartedPage";

const CdapGettingStarted = () => {
  return (
    <DefaultLayout showMenu={false}>
      <div className='container mx-auto px-6 lg:px-20 '>
        <div className='home-page cdap entry-content prose max-w-none mt-0 lg:mt-0 lg:mb-4 wp-block-group'>
          <CdapGettingStartedPage />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default CdapGettingStarted;
