import React from 'react';
import { InlineWidget } from 'react-calendly';

export const CdapGettingStartedPage = () => {
  return (
    <div className='flex flex-col items-center pb-16'>
      <div className='why-us-section mt-0 wp-block-group sections__container'>
        <h2 className='text-white'>Get your $15,000 grant and $100,000 0% loan with Argento CPA</h2>
      </div>
      <div className='hidden lg:block'>
        <InlineWidget
          url='https://calendly.com/jared-argento/strategy-call-30-minutes'
          styles={{
            height: '1200px',
            width: '750px',
          }}
        />
      </div>
      <div className='block lg:hidden'>
        <InlineWidget
          url='https://calendly.com/jared-argento/strategy-call-30-minutes'
          styles={{
            height: '1200px',
          }}
        />
      </div>
    </div>
  );
};
